@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&;900display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;900&display=swap');

* {
    padding: 0;
    margin: 0;
    transition-property: opacity background color left right height width box-shadow border bottom;
    box-sizing: border-box;
    @apply duration-200;
    scroll-behavior: smooth;
    
}
::moz-selection{
    @apply !text-white !bg-light-green;
}
::selection{
    @apply !text-white !bg-light-green;
}

body[dir="ltr"] * {
font-family:"Poppins";
}
body[dir="rtl"] * {
font-family:"Cairo";
}


body {
    /* background: linear-gradient(-45deg, #19AB8D05, #85AB1905, #71AB1905 ,#ABA11905, #AB841905, #AB191905 ); */
    @apply body-bg-gradient;
}

.gradient-custom:hover {
    @apply hover-gradient-custom;
}

.heading {
    @apply text-4xl text-light-green my-5 font-[600] tracking-[1px];
}

/* width */
::-webkit-scrollbar {
    @apply w-[8px];
}

/* Track */
::-webkit-scrollbar-track {
    /*background: linear-gradient(-135deg, #f3fffd, #fafff3, #fff3f3)*/
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-light-green;
}

/*! Active Classes*/
.active-li {
    @apply border-b-light-green !text-light-green;
}

.active-ol {
    @apply underline text-dark-black;
}

.active-img {
    @apply shadow-xl;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-dark-grey;
}

input:focus,
textarea:focus {
    @apply border-light-green border-[2px];
}

.swiper-pagination-bullet {
    /* background-color: #f0f !important; */
    @apply !bg-light-green;
}

.swiper-pagination-bullet-active {
    @apply !w-3 !rounded-[30%];
}

/* ======================================================================= */
.swiper-button-next {
    @apply !-top-[50px];
}

.swiper-button-next:after,
.swiper-button-next:before,
.swiper-button-prev:after,
.swiper-button-prev:before {
    @apply !content-[""];
}

/* =========== modefia button swiper*/
.swiper-button-prev,
.swiper-rtl,
.swiper-button-next {
    @apply !px-3 opacity-100 !inline-block !static !w-auto cursor-pointer;
}

.avatar {
    background: url('@assets/icons/Avatar.svg') center no-repeat;
    background-size: cover;
}

footer::before {
    @apply border-t-white border-t-[10px] absolute content-[''] w-full h-[350px] bg-light-green left-[50%] -top-[90px] -z-10 translate-x-[-50%] skew-y-[3deg];
}

/* card last news */
#card:hover #blur-layer {
    @apply backdrop-blur-[10px];
}

#card:hover #show-icon {
    @apply opacity-100;
}

#card:hover #title {
    @apply text-white text-2xl translate-x-0 translate-y-[5.625rem]; //transform: translate(0, -90px); } #card:hover #text, #card:hover .date { @apply translate-x-0 translate-y-[-1.25rem]; //transform: translate(0, -1.25rem); } @keyframes animateTicker { 0% { transform: translateX(100%); } 100% { transform: translateX(-100%); } } @keyframes animateTicker2 { 0% { transform: translateX(0); } 100% { transform: translateX(-200%); } }